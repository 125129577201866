import { inject, onMounted, onUnmounted, reactive } from 'vue';
import Segments from './Segments';
import { useRoute } from 'vue-router';
const { ProductViewed,ProductViewedNonLoggedIn } = Segments();

export default function Product() {

    let product_id;
    const axios = inject('axios');

    const product = reactive({
        loading: false,
        data: [],
        error: '',
        watchlist: 0
    })
    const route = useRoute();

    const getProduct = async() => {
        if (axios.isLoggedIn) {
            product.loading = true;
            let slug = route.params.slug;

            axios.authApi.get('/product/' + slug).then((resp) => {
                if (resp.status) {
                    product.data = resp.data.data;
                    document.getElementsByTagName("title")[0].innerHTML = `${product.data.product.name} - ${product.data.product.brand} - Wholesale`;
                    product_id = product.data.product.id;
                    product.watchlist = product.data.product.watchlist ? 1 : 0;
                    getproductBulkDiscount();
                    ProductViewed(route,product.data);
                } else {
                    product.error = resp.data.error;
                }
            }).catch(function(error) {
                console.log(error);
            }).finally(() => (product.loading = false));
        } else {
            product.loading = true;
            let slug = route.params.slug;

            axios.api.get('/products/' + slug).then((resp) => {
                if (resp.status) {
                    product.data = resp.data.data;
                    // product_id = product.data.product.id;
                    ProductViewedNonLoggedIn(route,product.data);
                } else {
                    product.error = resp.data.error;
                }
            }).catch(function(error) {
                console.log(error);
            }).finally(() => (product.loading = false));
        }
    };
    const compatible = reactive({
        loading: false,
        data: [],
        error: ''
    })
    const getCompatibleproducts = async() => {
        if (axios.isLoggedIn) {
            compatible.loading = true;
            let slug = route.params.slug;

            axios.authApi.get('/compatibleProducts/' + slug).then((resp) => {
                if (resp.status) {
                    compatible.data = resp.data.data;
                } else {
                    compatible.error = resp.data.error;
                }
            }).catch(function(error) {
                console.log(error);
            }).finally(() => (compatible.loading = false));
        } else {}
    };

    const bulk = reactive({
        loading: false,
        data: [],
        error: ''
    })
    const getproductBulkDiscount = async() => {
        bulk.loading = true;
        axios.authApi.get('/productBulkDiscount/' + product_id).then((resp) => {
            if (resp.status) {
                bulk.data = resp.data.data;
            } else {
                bulk.error = resp.data.error;
            }
        }).catch(function(error) {
            console.log(error);
        }).finally(() => (bulk.loading = false));
    };

    const addtowatchlist = async() => {
        if(product.watchlist == 0){
            axios.authApi.post('/addWatchlist', {
                product_id: product_id
            }).then((resp) => {
                if (resp.status) {
                    product.watchlist = 1;
                }
            }).catch(function(error) {
                console.log(error);
            });
        } else {
            axios.authApi.get('/removeWatchlist', {
                params: {
                    product_id: product_id
                }
            }).then((resp) => {
                if (resp.status) {
                    product.watchlist = 0;
                }
            }).catch(function(error) {
                console.log(error);
            });
        }

    };

    return {
        product,
        getProduct,
        compatible,
        getCompatibleproducts,
        bulk,
        getproductBulkDiscount,
        addtowatchlist,
        isLoggedIn: axios.isLoggedIn,
    }

}